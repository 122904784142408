import React, {useState} from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Spinner } from "react-bootstrap";
import axios from "axios";

const LaunchForm = ({setWaitlistStatus, setSubmitModal, setSubmitMsg}) => {
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = (value) => {
    const formdata = new FormData();
    formdata.append("data[FullName]", value.fullname);
    formdata.append("data[PhoneNumber]", value.phone);
    formdata.append("data[Email]", value.email);

    setSubmitting(true)

    axios
      .post("https://sheetdb.io/api/v1/ptknvtcbpju1a", formdata)
      .then((data) => {
        setSubmitting(true)
        setWaitlistStatus(true)
        setSubmitMsg("Success")
        setSubmitModal(true)
        setSubmitting(false);
      })
      .catch((err) => {
        setSubmitting(true)
        setWaitlistStatus(false)
        setSubmitMsg(err.response.data.message)
        setSubmitting(false)
        setSubmitModal(true)
        console.log(err)
        setSubmitting(false)
      });
  };

  // const onSubmit = (values) => {
  //   let fullnameArray = values.fullname.split(" ");
  //   let firstname;
  //   let lastname;


  //   if(fullnameArray.length > 1){
  //     firstname = fullnameArray[0]
  //     lastname = fullnameArray[1]
  //   }
  //   else{
  //     firstname = fullnameArray[0]
  //     lastname = fullnameArray[0]
  //   }

  //   var req = {
  //     'firstName': firstname,
  //     'lastName': lastname,
  //     'email': values.email,
  //     'phoneNumber': values.phone
  //   }

  //   setSubmitting(true)
  //   axios.post(`https://testapi.manofx.com/api/waitlist`, req)
  //     .then(res => {
  //       setSubmitting(true)
  //       if(res.status === 200){
  //           setWaitlistStatus(true)
  //           setSubmitMsg(res.data.success)
  //       }
  //       else if(res.status === 201){
  //         setWaitlistStatus(false)
  //         setSubmitMsg(res.data.error)
  //       }
  //       setSubmitModal(true)
  //       setSubmitting(false)
  //     })
  //     .catch(err => {
  //       setSubmitting(true)
  //       setWaitlistStatus(false)
  //       setSubmitMsg(err.response.data.message)
  //       setSubmitting(false)
  //       setSubmitModal(true)
  //       console.log(err)
  //       setSubmitting(false)
  //     })
  // }
  return (
  <div>
    <Formik
      initialValues={{ email: "", fullname: "", phone: "" }}
      validate={(values) => {
        const errors = {};
        if (!values.email || !values.fullname || !values.phone) {
          errors.email = (
            <div className="requied">All fields are Required*</div>
          );
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = (
            <div className="all fields">Invalid email address</div>
          );
        }
        return errors;
      }}
      onSubmit={onSubmit}
    >
      {() => (
        <>
          <Form className="form--border">
            <div className="form-error-message">
              <ErrorMessage name="email" component="div" />
            </div>
            <div>
              <div className="form-row">
                <>
                  <Field
                    type="text"
                    name="fullname"
                    placeholder="Full name *"
                    className="input-margin-bottom form_input"
                  />
                </>
                <>
                  <Field
                    type="text"
                    name="phone"
                    placeholder="Phone number *"
                    className="input-margin-bottom form_input"
                  />
                </>
              </div>
              <div className="form-row">
                <>
                  <Field
                    type="email"
                    name="email"
                    placeholder="Email *"
                    className="input-margin-bottom form_input"
                  />
                </>
                <div className="submitButton">
                  <button type="submit" disabled={submitting}>
                    {submitting ?
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    :
                    "I want to Join"
                    }
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  </div>
)};
    
export default LaunchForm;
