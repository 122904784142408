import React from 'react';
import { Modal } from 'react-bootstrap';

const SubmitModal = ({show, handleClose, status, message}) => {
  return (
    <Modal centered show={show} onHide={handleClose}>
        <Modal.Body>
            <div className='sm-header'>{status ? "Thank You!" : "Failed!"}</div>
            <div className={status ? 'sm-icon success' : 'sm-icon failed'}>
                {status ?
                <span className="iconify" data-icon="akar-icons:circle-check-fill"></span>
                :
                <span className="iconify" data-icon="ic:baseline-cancel"></span>
                }
            </div>
            <div className="sm-body">{
                message
            }</div>
        </Modal.Body>
      </Modal>
  )
}

export default SubmitModal