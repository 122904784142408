import React, {useState} from "react";
import Logo_IMG from "../assets/images/exchange 3.jpg";
import Phone_img from "../assets/images/Phone body.png";
import Phone_img3 from "../assets/images/iPhone.png";
import Phone_img2 from "../assets/images/Phone body (2).png";
import Phone_img4 from "../assets/images/Phone body (3).png";
import PurpleCheckIcon from "../assets/icons/CheckPurple.svg";
import YellowCheckIcon from "../assets/icons/CheckYellow.svg";
import LaunchForm from "./LaunchForm";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import SubmitModal from "./SubmitModal";

const currentYear = new Date().getFullYear();
const boxs = [
  {
    Id: "1",
    icon: PurpleCheckIcon,
    title: "Exchange with local currency (LRD/USD)",
    content:
      "Quickly place an exchange order with your LRD or USD anytime of the day at the comfort of your home or office.",
    box_color: "yellow-backG",
  },
  {
    Id: "2",
    icon: YellowCheckIcon,
    title: "Directory of Traders",
    content:
      "With our approval system, you get a wide range of traders that are licensed to trade on our system.",
    box_color: "purple-backG",
  },
  {
    Id: "3",
    icon: PurpleCheckIcon,
    title: "Fund your wallet",
    content:
      "All transactions are performed with your Dual Wallet account giving you the option to fund your account.",
    box_color: "yellow-backG",
  },
  {
    Id: "4",
    icon: YellowCheckIcon,
    title: "Withdraw from your wallet",
    content:
      "Automatically withdraw your funds from your wallet to either your bank account or mobile money wallet.",
    box_color: "purple-backG",
  },
  {
    Id: "5",
    icon: PurpleCheckIcon,
    title: "Automatic Trading",
    content:
      "As a trader, set your timeframe for an automatic bot to help you perform trade when you are not available.",
    box_color: "yellow-backG",
  },
  {
    Id: "6",
    icon: YellowCheckIcon,
    title: "Secured Transaction",
    content:
      "Perform all trading transactions in a secured environment that gives you control over all your trade.",
    box_color: "purple-backG",
  },
];

function LaunchIndex() {
  const [submitModal, setSubmitModal] = useState(false)
  const [waitlistStatus, setWaitlistStatus] = useState(false)
  const [submitMsg, setSubmitMsg] = useState("")

  return (
    <div className="page--con">
      <SubmitModal 
        show={submitModal}
        handleClose={() => setSubmitModal(false)}
        status={waitlistStatus}
        message={submitMsg}
      />
      <div className="landing-soon--con">
        <div className="company-logo--con">
          <img src={Logo_IMG} alt="logo_image" className="logo-icon" />
          <div className="logo-name">Mano FX</div>
        </div>
        <section className="section1--con">
          <div className="section1--wrapper">
            <div className="phone-img--con">
              {/* <img src={Phone_img} className="Phone_img" alt="PhoneImage" /> */}
              <Carousel
                autoPlay={true}
                infiniteLoop={true}
                interval={5000}
                showArrows={false}
                showStatus={false}
                showThumbs={false}
                swipeable={true}
              >
                <div>
                  <img src={Phone_img} className="Phone_img" alt="PhoneImage" />
                </div>
                <div>
                  <img
                    src={Phone_img2}
                    className="Phone_img"
                    alt="PhoneImage"
                  />
                </div>
                <div>
                  <img
                    src={Phone_img3}
                    className="Phone_img"
                    alt="PhoneImage"
                  />
                </div>
                <div>
                  <img
                    src={Phone_img4}
                    className="Phone_img"
                    alt="PhoneImage"
                  />
                </div>
              </Carousel>
            </div>
            <div className="form--con">
              <h1 className="form--launching-soon-text">Launching Soon</h1>
              <div className="form-paragraph-content">
                <p>
                  For customers seeking a quick way to exchange currency any
                  time of the day, who need a wide range of traders to choose
                  from and trade at a reasonable price, ManoFX is a digital
                  financial platform that brings together traders and customers
                  in a digital market environment to trade and carry out
                  exchange transactions.
                </p>
              </div>
              <div className="form--box-con">
                <div className="join-wait-list--text">Join the waitlist</div>
                <LaunchForm 
                  setWaitlistStatus={setWaitlistStatus}
                  setSubmitModal={setSubmitModal}
                  setSubmitMsg={setSubmitMsg}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="section2--con">
          <div className="section2--content Section2-box-main--con">
            {boxs.map((D) => {
              return (
                <div className={`box-con ${D.box_color}`}>
                  <div className="box-title">
                    {<img alt="check_icon" src={D.icon} className="icon-con" />}
                    {D.title}
                  </div>
                  <div className="box-content">{D.content}</div>
                </div>
              );
            })}
          </div>
        </section>
        <footer className="footer-con">
          <div className="footer-content">
            (c) Copyright {currentYear} - ManoFX - a product facilitated by Sabinovates Technology & Innovation Inc.
          </div>
        </footer>
      </div>
    </div>
  );
}

export default LaunchIndex;
